@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

#assignment{
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 19px;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: center;
}