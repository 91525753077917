#check-the-reward-div{
    position: relative;
    width: 100%;
    height: 58px;
    background-color: #FAFAFA;
    border-radius: 8px;
    margin-top: 20px;
}

#check-the-reward-p{
    padding-left: 16px;
    font-family: Poppins, serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0.5px;
    text-align: left;
}

#reward-p{
    font-family: Poppins, serif;
    font-size: 12px;
    padding-left: 16px;
    font-weight: 800;
    line-height: 0;
    letter-spacing: 0.5px;
    text-align: left;
    color: #54A5DA;
}

.scroller{
    display: grid;
    grid-auto-flow: column;
    overflow-x: auto;
    height: 42px;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.eating-category-div{
    width: 110px;
    height: 30px;
    text-align: center;

    background: #FF00C7;
    border-radius: 16px;
}

#eating-category-item-id{
    margin: 2px;
}

#eating-item-main-div{
    width: 100%;
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0 12px 24px rgba(68, 68, 68, 0.1);
    border-radius: 12px;
    margin-bottom: 40px;
}

#eating-item-name{
    font-family: Poppins, serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 0;
    margin-bottom: 0;
}

#eating-item-strike-price{
    font-family: Poppins, serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 0;
    letter-spacing: 0.5px;
    text-align: left;
    margin: 0;
}

#eating-item-price{
    font-family: Poppins, serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 0;
    letter-spacing: 0.5px;
    color: #54A5DA;
}