.image-div{
    position: relative;
    width: 92vw;
    height: 92vw;
    background: url("../../public/assets/shop.png") no-repeat;
    background-size: cover;
    background-position-x: center;
}

.shop-image{
    /*position: absolute;*/
    width: 100%;
}

.location-div{
    background-color: white;
    height: 54px;
    width: 90%;
    position: absolute;
    margin : 5%;
    border-radius: 27px;
}

.space-align-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.space-align-block {
    flex: none;
    margin: 8px 4px;
    padding: 8px;
}

.red-circle-location{
    background-color: #FA5D5D;
    height: 40px;
    width: 40px;
    border-radius: 50px;
}

/*for vertically center*/
.outer {
    display: table;
    height: 100%;
    padding-top: 3px;
}

.middle {
    display: table-cell;
    vertical-align: middle;
}

.inner {
    margin-left: auto;
    margin-right: auto;
}
/*end of vertical center*/

p{
    font-family: 'Poppins', serif;
    font-style: normal;
    letter-spacing: 0.5px;
    font-weight: 600;
}
#monica{
    font-size: 20px;
    line-height: 19px;
}

#wattala{
    font-size: 14px;
    line-height: 0;
    color: rgba(0, 0, 0, 0.5);
}

.stars{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 6%;
}


